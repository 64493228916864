import { RadioGroup } from "@headlessui/react";
import InputBlock from "../components/InputBlock";
import { getZipCode } from "../utils";
import React, { useState } from "react";
import { useController, useForm } from "react-hook-form";
import { navigate } from "gatsby";
import "../css/index.css";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Tie from "../images/tie.svg";

const ContactPage = () => {
  const [gender, setGender] = useState<"Frau" | "Herr" | "">("");
  const { register, control } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      zipCode: "",
      city: "",
    }, mode: "onChange"
  });

  // write controllers for all inputs
  const {
    field: { onChange: setFirstName, value: firstName },
  } = useController({
    name: "firstName",
    control,
    rules: { required: true },
  });
  const {
    field: { onChange: setLastName, value: lastName },
  } = useController({
    name: "lastName",
    control,
    rules: { required: true },
  });
  const {
    field: { onChange: setEmail, value: email },
  } = useController({
    name: "email",
    control,
    rules: { required: true },
  });
  const {
    field: { onChange: setPhone, value: phone },
  } = useController({
    name: "phone",
    control,
    rules: { required: true },
  });
  const {
    field: { onChange: setZip, value: zip },
  } = useController({
    name: "zipCode",
    control,
    rules: { required: true },
  });
  // controller for city
  const {
    field: { onChange: setCity, value: city },
  } = useController({
    name: "city",
    control,
    rules: { required: true },
  });

  return (
    <>
      {" "}
      <div
        style={{
          backgroundColor: "white",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <Navigation noHero={true} hideOnStart={false} />
      </div>
      <div className="flex w-full justify-center items-center mb-20 mt-36">
        <div className="flex flex-col items-center justify-center w-full px-8">
          <div className="px-4 text-center">
            <Tie className="mx-auto mb-6 h-7" src="./tie.svg" alt="" />
            <p className="mt-1 mb-8 font-serif text-4xl font-normal text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">
              Kontaktdetails
            </p>
          </div>

          <form
            method={"POST"}
            action={"https://formsubmit.co/butler@brunchbutler.ch"}
            // onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col mt-5 space-y-5"
          >
            <input type="hidden" name="_next" value={`${typeof window !== "undefined" && window.location.origin}/vielen-dank`}/>
            <RadioGroup
              value={gender}
              onChange={setGender}
              className="text-left flex flex-row gap-x-5 mt-3"
            >
              <RadioGroup.Option value="Frau">
                {({ checked }) => (
                  <div className="flex items-center cursor-pointer space-x-2">
                    <input
                      checked={checked || gender === "Frau"}
                      required={false}
                      className="text-darkGray focus:ring-0 cursor-pointer"
                      type="radio"
                      name="variant"
                    />
                    <span
                      onClick={() => (checked = !checked)}
                      className={!checked ? "text-standardGray" : undefined}
                    >
                      Frau
                    </span>
                  </div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="Herr">
                {({ checked }) => (
                  <div className="flex items-center cursor-pointer space-x-2">
                    <input
                      checked={checked || gender === "Herr"}
                      required={false}
                      className="text-darkGray focus:ring-0 cursor-pointer"
                      type="radio"
                      name="variant"
                    />
                    <span
                      onClick={() => (checked = !checked)}
                      className={!checked ? "text-standardGray" : undefined}
                    >
                      Herr
                    </span>
                  </div>
                )}
              </RadioGroup.Option>
            </RadioGroup>
            <InputBlock
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              label="Vorname"
              // required={deliveryOption === "beschenkte senden"}
              className="w-full"
              type="text"
              required
            />
            <InputBlock
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              label="Nachname"
              className="w-full"
              type="text"
              required
              // required={deliveryOption === "beschenkte senden"}
            />
            <InputBlock
              className="w-full mt-6"
              label="Telefonnummer"
              type="text"
              placeholder="+41 ..."
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              // register={{ ...register("shipping_phone") }}
              required
            />
            <div className="flex space-x-3">
              {/*<InputBlock*/}
              {/*  register={{ ...register("streetName") }}*/}
              {/*  className="w-full"*/}
              {/*  // required={deliveryOption === "beschenkte senden"}*/}
              {/*  type="text"*/}
              {/*  label="Strasse"*/}
              {/*/>*/}
              {/*<InputBlock*/}
              {/*  register={{ ...register("houseNumber") }}*/}
              {/*  label="Hausnummer"*/}
              {/*  // required={deliveryOption === "beschenkte senden"}*/}
              {/*  type="text"*/}
              {/*/>*/}
              <InputBlock
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                label="Email"
                className="w-full"
                type="email"
                required
              />
            </div>

            <div className="flex space-x-3">
              <InputBlock
                label="PLZ"
                onChange={(e) => setZip(e.target.value)}
                value={zip}
                required
                type="text"
              />
              <InputBlock
                value={city}
                onChange={(e) => setCity(e.target.value)}
                label="Ort"
                required
                type="text"
                className="w-full"
              />
            </div>
            <div>
              <label className="text-sm leading-loose label text-[#808080]">
                Nachricht*
                <textarea
                  required
                  className="w-full h-32 text-gray-700 textarea customInput resize-none"
                  name="Nachricht"
                />
              </label>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <input id="terms" required type="checkbox" />
              <label htmlFor="terms">
                Sie akzeptieren die{" "}
                <a href="/agb" className="text-olive">
                  Allgemeinen Geschäftsbedingungen
                </a>{" "}
                *
              </label>
            </div>
            <button
              type="submit"
              className="w-full px-4 py-3 mt-5 text-base font-light text-white uppercase border border-transparent bg-olive hover:bg-oliveStrong focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500"
            >
              Kontakt
            </button>
            <button
              type="button"
              onClick={() => navigate("/")}
              className="w-full px-4 py-3 mt-5 text-base font-light text-white uppercase border border-transparent bg-graniteGray hover:bg-oliveStrong focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500"
            >
              Zurück Zur Hauptseite
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
